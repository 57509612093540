.app-logo {
  max-height: 72px;
}

.app-logo-teko {
  max-height: 60px;
}

.background {
  background-image: url(/assets/background.svg);
  background-color: aliceblue;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;

  display: flex;
  min-height: 100vh;
}

.form-header {
  min-height: 60px;
  background-color: #fff;
  border-bottom: 1px solid silver;
  display: flex;

  .name-gretting {
    color: chocolate;
  }
}

.form-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;

  overflow-y: auto;
  overflow-y: overlay;
  scrollbar-width: thin;
}

.form-content {
  width: 420px;
  text-align: center;
}

.form-container-profile {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  overflow-y: auto;
  overflow-y: overlay;
  scrollbar-width: thin;
  max-height: calc(100vh - 60px);
}

.form-content-profile {
  width: 100%;
}

.form-layout {
  position: relative;
  padding: 32px;
  border-radius: 0px !important;
}

@media screen and (max-width: 600px) {
  .form-content {
    width: 100%;
  }

  .form-layout {
    padding: 32px 16px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #8888;
}

::-webkit-scrollbar-thumb:hover {
  background: #888c;
}
