.BorderGroup-container {
  > .BorderBox-container {
    padding: 18px 16px;
    border: 1px solid silver;
    border-top: none;
    border-bottom: none;
  }

  > .BorderBox-container:first-child {
    border-top: 1px solid silver;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  > .BorderBox-container:last-child {
    border-bottom: 1px solid silver;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
