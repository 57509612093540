.form-item-header {
  display: flex;
}

.form-item-label {
  width: 160px;
  align-self: flex-end;
  color: #777;
  text-transform: uppercase;

  font-size: small;
  font-weight: 600;
}

.form-item-label-no-upper {
  width: 160px;
  align-self: flex-end;
  color: #777;

  font-size: large;
  font-weight: 600;
}

.form-item-content {
  align-self: flex-end;
}

.form-item-update {
  margin-left: 160px;
  display: inline;

  > .shower {
    color: #000000de;
    display: inline;
    line-height: 32px;
  }
}

.form-item-confirm {
  white-space: nowrap;

  > .shower {
    display: inline;
    line-height: 32px;
  }
}

.form-item-header.medium {
  .form-item-label {
    width: 280px;
  }

  .form-item-update {
    margin-left: 280px;
  }
}

@media screen and (max-width: 600px) {
  .form-item-header {
    display: inline;
  }

  .form-item-update {
    margin-left: 0px;
  }

  .form-item-confirm {
    display: block;
  }
}

.user-avatar {
  display: flex;
  justify-content: center;
  position: relative;
}

.camera-frame {
  position: absolute;
  bottom: 0px;
  margin-left: -40px;
  border-radius: 50%;
  background-color: #fff;

  .MuiIconButton-root {
    padding: 6px;
    box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3), 0 1px 3px 1px rgba(65, 69, 73, 0.15);
    color: #654321;
  }
}

.user-menu-container {
  .MuiMenu-paper {
    width: 320px;
    box-shadow: none;
    border: 1px solid #ccc;
  }

  .MuiList-root {
    padding: 0px;
  }

  .MuiButton-root {
    font-weight: 400;
    text-transform: none;
  }
}

.user-menu-item {
  padding: 18px 16px;
  text-align: center;
}

.form-language-switcher {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.language-switcher-button {
  display: none;
}
