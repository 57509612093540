.form-content-profile {
  .ant-collapse {
    background-color: #fff;
  }

  .ant-collapse-content {
    border-top: none;

    > .ant-collapse-content-box {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .ant-collapse-header {
    min-height: 60px;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }

  .ant-collapse-header:hover {
    background-color: #ececec;
  }

  .ant-collapse-panel-header {
    border-bottom: none !important;

    .ant-collapse-arrow,
    .ant-collapse-content {
      display: none !important;
    }
  }

  .ant-collapse-panel-no-background {
    .ant-collapse-header:hover {
      background: none !important;
    }
  }
}

.ant-collapse.read-only {
  .ant-collapse-header {
    cursor: inherit !important;
  }
}

.ant-modal-footer {
  display: none;
}

.ant-notification {
  z-index: 1301 !important;
}
