.flex-1 {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-inline {
  display: inline-flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-items-center,
.align-items-start,
.align-items-end,
.justify-content-between,
.justify-content-center,
.justify-content-start,
.justify-content-end {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.box-icon {
  width: fit-content;
  margin: 5px 10px;
  flex-direction: column;
  #google-login {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0 !important;
  }
  .icon:hover {
    cursor: pointer;
  }
}

.px-24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.my-12 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-36 {
  margin-right: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mx-36 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.my-4 {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.my-36 {
  margin-bottom: 36px !important;
  margin-top: 36px !important;
}

.mx-10vw {
  margin-left: 10vw !important;
  margin-right: 10vw !important;
}

.my-10vh {
  margin-bottom: 10vh !important;
  margin-top: 10vh !important;
}

.color-gray {
  color: gray;
}

.color-error {
  color: orangered;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.text-bolder {
  font-weight: 600;
}

.width-20vw {
  width: 20vw;
}

.border-1px-solid-gray {
  border: 1px solid #d9d9d9;
}

.border-radius-4 {
  border-radius: 4px;
}
