.MuiButton-list {
  .MuiButton-label {
    justify-content: flex-start;
  }

  .MuiButton-root {
    text-transform: none;
    font-weight: 400;
  }
}

.MuiLink-item {
  .MuiLink-root {
    text-decoration: none !important;
  }
}

.app-menu {
  a,
  a:hover {
    color: unset;
  }

  .MuiMenuItem-root {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;

    padding: 10px 24px;
    min-width: 240px;

    .MuiSvgIcon-root {
      margin-right: 12px;
    }
  }

  .MuiMenuItem-root.active {
    background: #e8f0fe;
    color: #1967d2;
  }

  .MuiMenuItem-root.inactive {
    color: #999;
  }
}

.MuiTypography-subtitle1 {
  font-size: 1.125rem !important;
}

.DialogLogin > .MuiBackdrop-root {
  background-color: #fff !important;
}

.MuiDialog-root {
  .MuiDialog-scrollPaper {
    align-items: flex-start;
  }

  .MuiDialog-paper {
    margin-top: 96px;
    padding: 18px;
    max-width: unset;

    scrollbar-width: thin;
  }
}

.Dialog-small-container {
  width: 420px;
}

@media screen and (max-width: 600px) {
  .Dialog-small-container {
    width: calc(100vw - 120px);
  }
}

.DialogLogin {
  > .MuiDialog-container > .MuiPaper-root {
    box-shadow: none;
    border: 1px solid #dadada;
    border-radius: 8px;
  }
}
